$(document).foundation();



if ($('[data-ajax-modal]').length) {
  var modal = $('<div></div>')
    .addClass('large reveal')
    .attr('data-reveal', '')
    .append($('<h1>Hello</h1>'))
    .appendTo($('body'));

  var popup = new Foundation.Reveal(modal);

  $('[data-ajax-modal]').on('click', function(e){
    if (Foundation.MediaQuery.atLeast('medium')) {
      e.preventDefault();

      modal.html('<iframe src="' + $(this).attr('href') + '"></iframe>');
      popup.open();
    }
  });
}









$('#mega-menu').on('on.zf.toggler off.zf.toggler', function() {
  $('[data-toggle="mega-menu"]')
    .children('.fa')
    .toggleClass('fa-bars fa-times');
});











$.getScript('https://cdn.jsdelivr.net/jquery.slick/1.6.0/slick.min.js', function(){
    $('head')
        .append('<link rel="stylesheet" href="//cdn.jsdelivr.net/jquery.slick/1.6.0/slick.css">')
        .append('<link rel="stylesheet" href="//cdn.jsdelivr.net/jquery.slick/1.6.0/slick-theme.css">');

    $('.argument-list').slick({
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        speed: 3000,

        responsive: [{
            breakpoint: 640,
            settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }],
    });

    $('.customer-quotes').slick({
        arrows: false,
        //autoplay: true,
        autoplaySpeed: 5000,
        dots: true,
        //speed: 3000
    });
});

if ($('.level_1').length) {
  var accordion = new Foundation.AccordionMenu($('.level_1'));
  $('.level_1').foundation('toggle', $('[data-overview-open] > ul'));
}

$('#left').append(
  '<button class="button button--hollow toggle-button" id="toggle-menu" type="button">' +
    '<img alt="" src="/assets/img/icon_text_scale.svg" height="20" width="20">' +
  '</button>'
);

$('#toggle-menu').on('click', function(){
    $('#main').toggleClass('extend-content');
});

// JavaScript
window.sr = ScrollReveal({
    reset: true,
    mobile: false
});

sr.reveal('.container .is-active .stat-box', {
    duration: 1000,
    origin: 'right',
    distance: '10vw'
});

sr.reveal('.container .author:first-child', {
    duration: 1000,
    origin: 'left',
    distance: '10vw'
});

sr.reveal('.container .author:last-child', {
    duration: 1000,
    origin: 'right',
    distance: '10vw'
});
